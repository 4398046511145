import { Space } from "antd";
import styles from "./styles.module.css";

const SectionMarker = ({ props }) => {
    return (
        <Space props className={styles["section-marker"]}>
            <span
                style={{
                    display: 'block',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#D9D9D9',
                }}
            />
            <span
                style={{
                    display: 'block',
                    width: '61px',
                    height: '4px',
                    backgroundColor: '#D9D9D9',
                }}

            />
        </Space>
    );
}

export default SectionMarker;

