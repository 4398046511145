import {Flex, Typography, Space, Grid} from "antd";
import SectionMarker from "../../../components/section-marker/SectionMarker";
import {Form, Input, Button, notification} from 'antd';
import styles from "./styles.module.css";
import {SmartCaptcha} from '@yandex/smart-captcha';
import {useEffect, useRef, useState} from "react";
import { postContactForm } from "../../../api";
import { SMART_CAPTCHA_CLIENT_KEY } from "../../../config";

const ContactSection = () => {

    const [api, contextHolder] = notification.useNotification();

    const [token, setToken] = useState('');

    const [isSending, setIsSending] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const contactNameInput = useRef(null);
    const contactPhoneInput = useRef(null);
    const contactEmailInput = useRef(null);

    const tryToSendMail = async () => {
        setIsSending(true);
        const formRequest = {
            phone: contactPhoneInput.current.input.value,
            name: contactNameInput.current.input.value,
            email: contactEmailInput.current.input.value,
            token: token
        };

        try {
            await postContactForm(formRequest);
            api.open({
                message: 'Данные успешно отправлены',
                description:
                    'Спасибо за обращение, ваши данные были успешно отправлены',
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsSending(false);
        }
    };

    useEffect(() => {
        if (token !== '') {
            setIsDisabled(false);
        }
    },[token])

    return (
        <section className={styles["contact-section"]}>
            {contextHolder}
            <Flex className={styles["contact-section-header"]}>
                <SectionMarker
                    style={{
                        position: "absolute",
                        left: "-200px",
                    }}
                />

                <Typography.Title
                    className={styles["contact-section-title"]}
                    style={{
                        fontWeight: "4/====9800",
                        color: "#ffffff",
                    }}
                    id="demo"
                >
                    Оставьте нам свои<br/>контакты
                </Typography.Title>
            </Flex>

            <Typography.Text className={styles["contact-section-description"]}>
                Заполните форму и мы свяжемся <br/>с вами как можно скорее.
            </Typography.Text>

            <Form className={styles["contact-section-form"]}>
                <Input
                    placeholder="Ваше имя"
                    style={{
                        marginBottom: "24px",
                        backgroundColor: "#FF7A45",
                        color: "#ffffff",
                        border: "0",
                        borderRadius: "0",
                        borderBottom: "1px solid #ffffff"
                    }}
                    ref={contactNameInput}
                />
                <Input
                    placeholder="Ваш номер телефона"
                    style={{
                        marginBottom: "24px",
                        backgroundColor: "#FF7A45",
                        color: "#ffffff",
                        border: "0",
                        borderRadius: "0",
                        borderBottom: "1px solid #ffffff"
                    }}
                    ref={contactPhoneInput}
                />
                <Input
                    placeholder="E-mail"
                    style={{
                        backgroundColor: "#FF7A45",
                        color: "#ffffff",
                        border: "0",
                        borderRadius: "0",
                        borderBottom: "1px solid #ffffff"
                    }}
                    ref={contactEmailInput}
                />

                <Button
                    style={{
                        marginTop: "32px",
                    }}
                    variant="outlined"
                    className={styles["primary-button"]}
                    loading={isSending}
                    disabled={isDisabled}
                    onClick={() => tryToSendMail()}
                >Отправить</Button>
            </Form>

            <div className={styles["contact-section-captcha"]}>
                <SmartCaptcha sitekey={`${SMART_CAPTCHA_CLIENT_KEY}`} onSuccess={setToken} language={'ru'}/>
            </div>

        </section>
    );
};

export default ContactSection;
