import { Flex, Typography } from "antd";
import styles from "./styles.module.css";
import DeliveryImage from "../assets/delivery-image.png";

const DeliveryTabContent = () => {
    return (
        <Flex justify={"space-between"}>
            <Flex vertical className={styles["tabs-section-content"]}>
                <Typography.Title
                    style={{
                        fontSize: "24px",
                        fontWeight: "400",
                        marginBottom: "0",
                    }}
                >
                    Компании доставки и логистики
                </Typography.Title>
                <Typography.Text
                    style={{
                        paddingTop: "24px",
                    }}
                >
                    Продляем срок работы курьеров в компании, повышая их NPS
                    и эффективность работы.
                </Typography.Text>

                <Flex className={styles["target-section-list"]}>
                    <Flex vertical className={styles["target-section-list-item"]}>
                        <Flex gap={"16px"}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Повышаем лояльность курьеров, увеличиваем их срок работы с 9 до 11
                                месяцев.
                            </Typography.Text>
                        </Flex>

                        <Flex gap={"16px"} style={{marginTop: "24px"}}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Снижаем количество конфликтных ситуаций с собственниками и охраной
                                домов.
                            </Typography.Text>
                        </Flex>
                    </Flex>

                    <Flex vertical className={styles["target-section-list-item"]}>
                        <Flex gap={"16px"}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Повышаем эффективность работы курьеров, которые начинают выполнять
                                больше заказов.
                            </Typography.Text>
                        </Flex>

                        <Flex gap={"16px"} style={{marginTop: "24px"}}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Делаем простую интеграцию, не требуется ставить дополнительный софт.
                            </Typography.Text>
                        </Flex>
                    </Flex>

                </Flex>
                <img className={styles["target-section-image"]} src={DeliveryImage} alt="delivery"/>
            </Flex>
        </Flex>
    );
};

export default DeliveryTabContent;