import { Flex, Typography } from "antd";
import styles from "./styles.module.css";

const IntegrationBenefit = ({ children, icon, color, alt}) => {
    return (
        <Flex
            vertical
            justify={'space-between'}
            className={styles["integration-benefit"]}
            style={{
                backgroundColor: color,
            }}
        >
            <Typography.Title
                className={styles["integration-benefit-title"]}
                style={{
                    color: "#ffffff",
                    fontWeight: "400",
                }}
            >
                {children}
            </Typography.Title>
            <img
                src={icon}
                style={{
                    alignSelf: 'flex-end'
                }}
                alt={alt}
            />
        </Flex>
    );
}

export default IntegrationBenefit;