import { Flex, Typography } from "antd";
import styles from "./styles.module.css";
import InfrastructureImage from "../assets/infrastructure-image.png";

const InfrastructureTabContent = () => {
    return (
        <Flex justify={"space-between"}>
            <Flex vertical className={styles["tabs-section-content"]}>
                <Typography.Title
                    style={{
                        fontSize: '24px',
                        fontWeight: '400',
                        marginBottom: "0",
                    }}
                >
                    Владельцы инфраструктуры
                </Typography.Title>
                <Typography.Text
                    style={{
                        paddingTop: '24px',
                    }}
                >
                    Обеспечиваем порядок и контроль в неорганизованном существующем процессе,
                    защищаем инфраструктуру и приносим дополнительный доход.
                </Typography.Text>

                <Flex className={styles["target-section-list"]}>
                    <Flex vertical className={styles["target-section-list-item"]}>
                        <Flex gap={'16px'}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Совместно обеспечиваем безопасность и контроль на территоррии,
                                до 80% снижаем количество инциндентов.
                            </Typography.Text>
                        </Flex>

                        <Flex gap={'16px'} style={{marginTop: '24px'}}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Интегрируем решение в любую инфраструктуру за 2-4 недели.
                            </Typography.Text>
                        </Flex>
                    </Flex>

                    <Flex vertical className={styles["target-section-list-item"]}>
                        <Flex gap={'16px'}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Снижаем нагрузку на операторов call-центров, экономим до 50% времени.
                            </Typography.Text>
                        </Flex>

                        <Flex gap={'16px'} style={{marginTop: '24px'}}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Обеспечиваем дополнительный доход.
                            </Typography.Text>
                        </Flex>
                    </Flex>
                </Flex>
                <img className={styles["target-section-image"]} src={InfrastructureImage} alt="infrastructure"/>
            </Flex>
        </Flex>
    );
};

export default InfrastructureTabContent;