import { Flex, Typography } from "antd";
import Logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const FooterSection = () => {
    return (
        <footer className={styles["footer-section"]}>
            <Flex className={styles["footer-section-contacts"]}>
                <img
                    src={Logo}
                    alt="logo"
                    style={{
                        width: "283px",
                        height: "84px",
                    }}/>

                <nav className={styles["footer-section-navigation"]}>
                    <Flex
                        gap="75px"
                        className={styles["footer-section-navigation-list"]}
                    >
                        <ul
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                                listStyleType: "none",
                                padding: "0",
                                margin: "0",
                            }}
                        >
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/home"
                                    style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Главная
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/news"
                                    style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Новости
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/partners"
                                    style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Партнеры
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/contacts"
                                    style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Контакты
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/faq"
                                    style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                    }}
                                >
                                    FAQ
                                </Link>
                            </li>
                        </ul>

                        <ul
                            style={{
                                listStyleType: "none",
                                padding: "0",
                                margin: "0",
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                            }}
                        >
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/customer"
                                    style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Клиентам
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/customer/marketplaces"
                                    style={{
                                        fontSize: "12px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Маркетплейсам, <br/>службам доставки
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/customer/aggregators"
                                    style={{
                                        fontSize: "12px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Агрегаторам
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/customer/options"
                                    style={{
                                        fontSize: "12px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Варианты <br/>подключения (API)
                                </Link>
                            </li>
                        </ul>

                        <ul
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                                listStyleType: "none",
                                padding: "0",
                                margin: "0",
                            }}
                        >
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/tariff"
                                    style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Тарифы
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/tariff/rbtariffs"
                                    style={{
                                        fontSize: "12px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Тарифы РБ
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/tariff/rftariffs"
                                    style={{
                                        fontSize: "12px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Тарифы РФ
                                </Link>
                            </li>
                        </ul>

                        <ul
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                                listStyleType: "none",
                                padding: "0",
                                margin: "0",
                            }}
                        >
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/instructions"
                                    style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Инструкции
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/instructions/drivers"
                                    style={{
                                        fontSize: "12px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Водителям
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/instructions/business"
                                    style={{
                                        fontSize: "12px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Председателям ЖК
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://www.tpsgate.com/instructions/users"
                                    style={{
                                        fontSize: "12px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Жителям ЖК
                                </Link>
                            </li>
                        </ul>
                    </Flex>
                </nav>
            </Flex>

            <Flex className={styles["footer-section-copyright"]}>
                <Typography.Text
                    style={{
                        fontSize: "14px",
                        color: "#ffffff",
                    }}
                >
                    Все права защищены</Typography.Text>

                <ul className={styles["footer-section-copyright-list"]}>
                    <li>
                        <Typography.Text
                            style={{
                                fontSize: "14px",
                                color: "#ffffff",
                            }}
                        >
                            ©2024 TPS
                        </Typography.Text>
                    </li>
                    <li>
                        <Typography.Text
                            style={{
                                fontSize: "14px",
                                color: "#ffffff",
                            }}
                        >
                            Публичная оферта
                        </Typography.Text>
                    </li>
                    <li>
                        <Typography.Text
                            style={{
                                fontSize: "14px",
                                color: "#ffffff",
                            }}
                        >
                            info@tpsgate.ru
                        </Typography.Text>
                    </li>
                </ul>
            </Flex>
        </footer>
    );
};

export default FooterSection;
