import { Flex, Typography, Button } from "antd";
import IntegrationImage from "../assets/intergarion-image.jpg"
import SectionMarker from "../../../components/section-marker/SectionMarker";
import IntegrationBenefit from "../../../components/integration-benefit/IntegrationBenefit";
import IconWebPanel from "../assets/icon-web-panel.svg"
import IconSupport from "../assets/icon-support.svg"
import IconAccess from "../assets/icon-access.svg"
import IconManager from "../assets/icon-manager.svg"
import { Col, Row } from "antd";
import styles from "./styles.module.css";
import {Link} from "react-router-dom";

const IntegrationSection = () => {
    return (
        <section className={styles["integration-section"]}>
            <Flex className={styles["integration-section-content"]}>
                <SectionMarker
                    style={{
                        position: "absolute",
                        left: "-20px",
                    }}
                />

                <Typography.Title
                    className={styles["integration-section-title"]}
                    style={{
                        fontWeight: "400",
                        marginBottom: 0,
                    }}
                >
                    Удобство интеграции
                </Typography.Title>
            </Flex>

            <Flex className={styles["integration-section-description"]}>
                <img src={IntegrationImage} alt="integration" />

                <Row gutter={[20, 20]}>
                    <Col className="gutter-row" span={12}>
                        <IntegrationBenefit
                            color={"#FF7A45"}
                            icon={IconWebPanel}
                            alt="Web Panel"
                        >
                            Web-панель управления
                        </IntegrationBenefit>
                    </Col>

                    <Col span={12}>
                        <IntegrationBenefit
                            color={"#8C8C8C"}
                            icon={IconAccess}
                            alt="Access Panel"
                        >
                            Доступ <br/> в Info Hub
                        </IntegrationBenefit>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <IntegrationBenefit
                            color={"#8C8C8C"}
                            icon={IconSupport}
                            alt="Support Panel"
                        >
                            Саппорт <br/>24/7
                        </IntegrationBenefit>
                    </Col>

                    <Col span={12}>
                        <IntegrationBenefit
                            color={"#8C8C8C"}
                            icon={IconManager}
                            alt="Manager Panel"
                        >
                            Персональный менеджер
                        </IntegrationBenefit>
                    </Col>
                </Row>
            </Flex>

            <Flex
                vertical
                className={styles["integration-section-actions"]}
            >
                <Link to="https://apidocs.tpsgate.ru/" style={{ alignSelf: "center" }}>
                    <Button type="primary" className={styles["primary-button"]}>Документация TPS API</Button>
                </Link>
            </Flex>
        </section>
    );
};

export default IntegrationSection;