import { Flex, Typography, Space } from "antd";
import { Tabs } from 'antd';
import TargetDeliveryImage from '../assets/target-delivery-image.png'
import TargetInfrastructureImage from '../assets/target-infrastructure-image.png'
import TargetCourierImage from '../assets/target-courier-image.png'
import TargetResidentsImage from '../assets/target-residents-image.png'
import DeliveryTabContent from "./DeliveryTabContent";
import InfrastructureTabContent from "./InfrastructureTabContent";
import CourierTabContent from "./СourierTabContent";
import ResidentsTabContent from "./ResidentsTabContent";
import SectionMarker from "../../../components/section-marker/SectionMarker";
import styles from "./styles.module.css";
const { TabPane } = Tabs;

const TargetSection = () => {
    return (
        <section className={styles["target-section"] }
        >
            <Flex className={styles["target-section-content"]}>
                <SectionMarker
                    style={{
                        position: "absolute",
                        left: "-20px",
                    }}
                />

                <Typography.Title
                    className={styles["target-section-title"]}
                    style={{
                        fontWeight: "400",
                        textAlign: "center",
                        marginBottom: 0,
                    }}
                >
                    Ценность и функционал
                </Typography.Title>
            </Flex>

            <Flex style={{ width: '100%' }}>
                <Tabs type="card" className={styles["tabs-section"]}>
                    <TabPane
                        tab="Доставка и логистика"
                        key="1"
                        style={{
                            fontWeight: '400',
                            background: '#ffffff',
                            borderRadius: '0 16px 16px 16px',
                            border: '1px solid #f0f0f0d9',
                            borderTop: '0',
                        }}
                    >
                        <Flex
                            style={{
                                borderRadius: '0 16px 16px 0',
                                overflow: 'hidden',
                            }}>
                            <DeliveryTabContent/>
                            <img className={styles["tabs-target-section-image"]} src={TargetDeliveryImage} alt="target"/>
                        </Flex>
                    </TabPane>

                    <TabPane
                        tab="Инфраструктура"
                        key="2"
                        style={{
                            fontWeight: '400',
                            background: '#ffffff',
                            borderRadius: '0 0 0 16px',
                            border: '1px solid #f0f0f0d9',
                            borderTop: '0',
                        }}
                    >
                        <Flex
                            style={{
                                borderRadius: '0 16px 16px 0',
                                overflow: 'hidden',
                            }}
                        >
                            <InfrastructureTabContent/>
                            <img className={styles["tabs-target-section-image"]} src={TargetInfrastructureImage} alt="infrastructure"/>
                        </Flex>
                    </TabPane>

                    <TabPane
                        tab="Курьеры"
                        key="3"
                        style={{
                            fontWeight: '400',
                            background: '#ffffff',
                            borderRadius: '0 0 0 16px',
                            border: '1px solid #f0f0f0d9',
                            borderTop: '0',
                        }}
                    >
                        <Flex
                            style={{
                                borderRadius: '0 16px 16px 0',
                                overflow: 'hidden',
                            }}
                        >
                            <CourierTabContent />
                            <img className={styles["tabs-target-section-image"]} src={TargetCourierImage} alt="courier"/>
                        </Flex>
                    </TabPane>
                    <TabPane
                        tab="Жители дома"
                        key="4"
                        style={{
                            fontWeight: '400',
                            background: '#ffffff',
                            borderRadius: '0 0 0 16px',
                            border: '1px solid #f0f0f0d9',
                            borderTop: '0',
                        }}
                    >
                        <Flex
                            style={{
                                borderRadius: '0 16px 16px 0',
                                overflow: 'hidden',
                            }}
                        >
                            <ResidentsTabContent />
                            <img className={styles["tabs-target-section-image"]} src={TargetResidentsImage} alt="residents"/>
                        </Flex>
                    </TabPane>
                </Tabs>
                <Space
                    className={styles["target-section-image"]}
                    style={{
                        marginTop: '50px',
                        borderRadius: '0 0 10px 0',
                        overflow: 'hidden',
                        flexShrink: '0',
                    }}
                >
                </Space>
            </Flex>

            <Flex vertical className={styles["target-section-mobile"]}>
                <DeliveryTabContent />
                <InfrastructureTabContent />
                <CourierTabContent />
                <ResidentsTabContent />
            </Flex>
        </section>
    );
};

export default TargetSection;