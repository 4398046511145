import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IndexPage from './pages/index-page/IndexPage';
import { ConfigProvider } from "antd";
import { THEME } from './styles/Theme';

function App() {
    return (
        <ConfigProvider theme={THEME}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<IndexPage/>}/>
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    );
}

export default App;
