import { Flex } from "antd";
import MainSection from "./main-section/MainSection";
import TargetSection from "./target-section/TargetSection";
import IntegrationSection from "./integration-section/IntegrationSection";
import MapSection from "./map-section/MapSection";
import CompanySection from "./company-section/CompanySection";
import PartnerSection from "./partner-section/PartnerSection";
import ContactSection from "./contact-section/ContactSection";
import FooterSection from "./footer-section/FooterSection";
import styles from "./styles.module.css";

const IndexPage = () => {
    return (
        <Flex
            vertical
            gap="100px"
            style={{
                backgroundColor: "#f0f0f0",
            }}
        >
            <MainSection />
            <TargetSection />
            <IntegrationSection />
            <MapSection />
            <PartnerSection />
            <ContactSection />
            <FooterSection />
        </Flex>

    );
};

export default IndexPage;
