import { Flex, Typography } from "antd";
import styles from "./styles.module.css";
import CourierImage from "../assets/courier-image.png";

const CourierTabContent = () => {
    return (
        <Flex style={{ width: "100%" }} justify={"space-between"}>
            <Flex vertical className={styles["tabs-section-content"]}>
                <Typography.Title
                    style={{
                        fontSize: '24px',
                        fontWeight: '400',
                        marginBottom: "0",
                    }}
                >
                    Курьеры
                </Typography.Title>
                <Typography.Text
                    style={{
                        paddingTop: '24px',
                    }}
                >
                    Сохраняем время и нервы, <br/> позволяя зарабатывать больше за смену.
                </Typography.Text>

                <Flex className={styles["target-section-list"]}>
                    <Flex vertical className={styles["target-section-list-item"]}>
                        <Flex gap={'16px'}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                +2 выполненных заказа за смену за счет экономии времени.
                            </Typography.Text>
                        </Flex>

                        <Flex gap={'16px'} style={{marginTop: '24px'}}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Простота интеграции - просто кнопка в действующем рабочем приложении.
                            </Typography.Text>
                        </Flex>
                    </Flex>

                    <Flex vertical className={styles["target-section-list-item"]}>
                        <Flex gap={'16px'}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Помогаем с навигацией по территории жилых домов.
                            </Typography.Text>
                        </Flex>

                        <Flex gap={'16px'} style={{marginTop: '24px'}}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Сокращаем количество конфликтных ситуаций из-за проблем с доступом на объект.
                            </Typography.Text>
                        </Flex>
                    </Flex>

                    <img className={styles["target-section-image"]} src={CourierImage} alt="courier"/>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default CourierTabContent;