import { Flex, Typography, Space, Grid } from "antd";
import SectionMarker from "../../../components/section-marker/SectionMarker";
import PartnerCdek from "../assets/partner-cdek.png";
import PartnerGo from "../assets/partner-go.png";
import PartnerVv from "../assets/partner-vv.png";
import PartnerMail from "../assets/partner-mail.png";
import styles from "./styles.module.css";

const PartnerSection = () => {
    return (
        <section className={styles["partner-section"]}>
            <Flex className={styles["partner-section-content"]}>
                <SectionMarker
                    style={{
                        position: "absolute",
                        left: "-200px",
                    }}
                />

                <Typography.Title
                    className={styles["partner-section-title"]}
                    style={{
                        width: "100%",
                        fontWeight: "400",
                        marginBottom: 0,
                    }}
                >
                    > 130 компаний-партнеров
                </Typography.Title>
            </Flex>

            <Flex
                className={styles["partner-section-partners"]}
                justify="space-between"
                style={{
                    marginBottom: "45px",
                    alignItems: "center",
                }}
            >
                <img width={196} height={50} src={PartnerCdek} alt="Partner Cdek" />

                <img width={178} height={50} src={PartnerGo} alt="Partner Go" />

                <img width={298} height={35} src={PartnerVv} alt="Partner Vv" />

                <img width={279} height={69} src={PartnerMail} alt="Partner Mail" />
            </Flex>

            <Typography.Text
                style={{
                    fontSize: "14px",
                    color: "#8c8c8c"
                }}
            >
                А также:
                ООО "Д-Снаб" Delikateska, WhiteCabs,  ООО "Транс-престиж" ECP-Logistic, Грузоватор логистик,
                Полезная вода Wola, "SV-Logistik" (Москва, Яндекс)
                ЗЕСТ ЭКСПРЕСС, ООО "КС ИНТЕГРАЛ", ООО "Интернет Логистика" Topdelivery, СиДиПи,
                ООО "ДЛ-Транс" Москва.
            </Typography.Text>
        </section>
    );
};

export default PartnerSection;
