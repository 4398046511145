import { type ThemeConfig, theme } from "antd";
import "antd/dist/reset.css";

export const THEME: ThemeConfig = {
	algorithm: theme.defaultAlgorithm,
	token: {
		fontFamily: '"Inter", sans-serif',
		wireframe: true,
		colorPrimary: "#FF7A45",
		fontSize: "16px",
		fontWeight: "400",
	},
	components: {
		Tabs: {

			itemColor: "#ffffff",
			cardBg: "#FF7A45",
			itemSelectedColor: "#000000",
			horizontalItemPadding: '16px 38px',
			horizontalMargin: '0',
			cardGutter: '0px',
			titleFontSize: '14px',
		}
	}
};
