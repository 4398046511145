import { Flex, Typography } from "antd";
import MapImage from "../assets/map.jpg"
import SectionMarker from "../../../components/section-marker/SectionMarker";
import StatInformer from "../../../components/stat-informer/StatInformer";
import styles from "./styles.module.css";

const MapSection = () => {
    return (
        <section className={styles["map-section"]}>
            <Flex className={styles["map-section-header"]}>
                <SectionMarker
                    style={{
                        position: "absolute",
                        top: "45px",
                        left: "-20px",
                    }}
                />

                <Typography.Title
                    className={styles["map-section-title"]}
                    style={{
                        width: "100%",
                        fontWeight: "400",
                        marginBottom: 0,
                    }}
                >
                    Шлагбаумы TPS Gate <br/> на картах
                </Typography.Title>
            </Flex>

            <Flex
                vertical
                className={styles["map-section-description"]}
            >
                <Typography.Text
                    style={{
                        fontWeight: 400
                    }}
                >
                    На карте вы можете увидеть и проанализировать количество шлагбаумов,
                    подключённых к TPS Gate, в вашем регионе.
                </Typography.Text>
                <Typography.Text
                    style={{
                        fontWeight: 400,
                        marginTop: "16px",
                    }}
                >
                    С нашим сервисом шлагбаумы больше не будут проблемой для водителей,
                    доставок и такси. Добавьте свой шлагбаум в систему, скачав приложение
                    TPS Gate в Google Play или App Store.
                </Typography.Text>
            </Flex>


            <Flex  vertical gap={20} className={styles["map-section-information"]}>
                <div
                    style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >
                    <img width="100%" src={MapImage} alt="map" />
                </div>
                <Flex
                    justify="space-between"
                    gap={20}
                    className={styles["map-section-stat-information"]}
                >
                    <StatInformer info="13 000+" description="Водителей"/>
                    <StatInformer info="3 600+" description="Шлагбаумов в Москве"/>
                    <StatInformer info="50+" description="Шлагбаумов в Воронеже"/>
                    <StatInformer info="500+" description="Шлагбаумов в Беларуси"/>
                </Flex>
            </Flex>
        </section>
    );
};

export default MapSection;