import { Flex, Typography } from "antd";
import styles from "./styles.module.css";
import ResidentsImage from "../assets/residents-image.png";

const ResidentsTabContent = () => {
    return (
        <Flex
            justify={"space-between"}
            style={{ paddingBottom: "16px", width: "100%" }}
        >
            <Flex vertical className={styles["tabs-section-content"]}>
                <Typography.Title
                    style={{
                        fontSize: '24px',
                        fontWeight: '400',
                        marginBottom: "0",
                    }}
                >
                    Жители дома
                </Typography.Title>
                <Typography.Text
                    style={{
                        paddingTop: '24px',
                    }}
                >
                    Гарантируем удобство и безопасность.
                </Typography.Text>

                <Flex className={styles["target-section-list"]}>
                    <Flex vertical className={styles["target-section-list-item"]}>
                        <Flex gap={'16px'}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Не надо тратить время, чтобы открыть шлагбаум, домофон, выписать пропуск.
                            </Typography.Text>
                        </Flex>

                        <Flex gap={'16px'} style={{marginTop: '24px'}}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Разбор инцендентов совместно с курьерскими службами и блокировка нарушителей.
                            </Typography.Text>
                        </Flex>
                    </Flex>

                    <Flex vertical className={styles["target-section-list-item"]}>
                        <Flex gap={'16px'}>
                            <span className={styles["target-section-list-point"]}/>
                            <Typography.Text className={styles["target-section-list-text"]}>
                                Начиная от порчи имущества и до хамского поведения, легко найти правонарушителя
                                - доступ к журналу всех посещений курьерами дома через УК и чат дома.
                            </Typography.Text>
                        </Flex>
                    </Flex>
                </Flex>
                <img className={styles["target-section-image"]} src={ResidentsImage} alt="residents"/>
            </Flex>
        </Flex>
    );
};

export default ResidentsTabContent;