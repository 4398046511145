import React, { useState } from 'react';
import {Button, Flex, Typography, Modal, Input} from "antd";
import Logo from "../../../components/logo/Logo";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const MainSection = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <section className={styles["main-section"]}>
            <div className={styles["main-section-content"]}>
                <header className={styles["main-header"]}>
                    <Logo/>
                    <Link to="https://owner.tpsgate.com/signin">
                        <Button type="primary" className={styles["login-button"]}>Личный кабинет</Button>
                    </Link>
                </header>

                <Flex vertical style={{marginTop: "245px"}}>
                    <Typography.Title
                        className={styles["main-section-title"]}
                        style={{
                            fontWeight: "400",
                            color: "#ffffff",
                        }}
                    >
                        TPSGate — процессинговый центр безопасности для городской инфраструктуры
                    </Typography.Title>

                    <Typography.Text
                        style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#ffffff",
                        }}
                    >
                        Сервис быстрого проезда через шлагбаумы.
                    </Typography.Text>
                </Flex>

                <Flex style={{marginTop: "32px"}} gap="10px" className={styles["main-section-controls"]}>
                    <Link
                        to="https://storage.yandexcloud.net/data-tpsgate-ru/TPS%20%D0%B5%D0%B4%D0%B8%D0%BD%D1%8B%D0%B8%CC%86%20%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%20%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf">
                        <Button
                            type="primary"
                            className={styles["primary-button"]}
                        >
                            Скачать презентацию
                        </Button>
                    </Link>
                    <a href="#demo">
                        <Button
                            color="default"
                            variant="outlined"
                            className={styles["primary-button"]}
                            ghost
                        >
                            Демо-доступ
                        </Button>
                    </a>
                </Flex>

                <Modal
                    title="Войти в систему"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    closable={false}
                >
                    <form className={styles["login-form"]}>
                        <label for="emal-field">Имя пользователя или E-mail</label>
                        <Input
                            id="emal-field"
                            className={styles["input-field"]}
                            style={{
                                margin: "16px 0 24px 0",
                            }}
                        />
                        <label for="password-field">Пароль</label>
                        <Input
                            id="password-field"
                            className={styles["input-field"]}
                            style={{
                                margin: "16px 0 24px 0",
                            }}
                        />
                        <Button
                            type="primary"
                            className={styles["modal-button"]}
                            style={{
                                margin: "24px 0",
                            }}
                        >
                            Войти в систему
                        </Button>
                        <Link
                            to="/"
                            style={{
                                color: "#FF7A45"
                            }}
                        >
                            Забыли пароль?
                        </Link>
                    </form>
                    <forn className={styles["registration-form"]}>
                        <Typography
                            style={{
                                marginBottom: "24px",
                            }}
                        >
                            Новый пользователь?
                        </Typography>
                        <Button
                            type="primary"
                            className={styles["modal-button"]}
                        >
                            Зарегистрироваться
                        </Button>
                    </forn>
                </Modal>
            </div>
        </section>
    );
};

export default MainSection;