import { Flex, Typography } from "antd";
import styles from "./styles.module.css";

const StatInformer = ({ info, description}) => {
    return (
        <Flex vertical className={styles["stat-informer"]}>
            <div className={styles["stat-informer-point"]} />
            <Typography.Title
                className={styles["stat-informer-title"]}
                style={{
                    color: "#ffffff",
                    fontWeight: "400",
                }}
            >
                {info}
            </Typography.Title>

            <Typography.Text className={styles["stat-informer-description"]}>
                {description}
            </Typography.Text>
        </Flex>
    );
}

export default StatInformer;